import React from "react"
import { Link } from "gatsby"
import { Container, Row } from "react-bootstrap"
import {
  TestimoniesSection,
  TestimoniesCol1,
  TestimoniesCol2,
  TestimoniesImg,
  TestimoniesH2,
  TestimoniesBr,
  TestimoniesButton,
} from "./SendMoneyStyle"
import UserImg from "../../images/foto-pengguna.png"


function Testimonies({ propsData, isEnglish }) {
  return (
    <>
      <TestimoniesSection className="testimonies">
        <Container className="py-5">
          <Row>
            <TestimoniesCol1 md={6}>
              <TestimoniesImg src={UserImg} alt="user testimonies" />
            </TestimoniesCol1>
            <TestimoniesCol2>
              <TestimoniesH2>
                {propsData.Title1}
                <TestimoniesBr />
                {propsData.Title2}
                <TestimoniesBr />
                {propsData.Title3}
              </TestimoniesH2>
              <Link to={isEnglish ? "/en/download" : "/download"}>
                <TestimoniesButton>{propsData.Button}</TestimoniesButton>
              </Link>
            </TestimoniesCol2>
          </Row>
        </Container>
      </TestimoniesSection>
    </>
  )
}

export default Testimonies
