import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { Header } from "../HomeComponents/Header"
import Footer from "../FooterComponents/Footer"
import Cost from "./Cost"
import Testimonies from "./Testimonies"
import Media from "./Media"
import DeliveryTime from "./DeliveryTime"
import HowTo from "./HowTo"
import FeatureSection from "../FeatureSection/FeatureSection"
import VideoMainSection from "../VideoMainSection/VideoMainSection"
import DownloadSection from "../DownloadAppSection/DownloadSection"
import MissionSection from "../Mission Section/MissionSection"
import { MainPageJson, PathJson, SendMoneyJson } from "../location"
import Seo from "../SEOComponents/Seo"
import Download from "../HomeComponents/Download"
import { RegisterButton } from "./RegisterButton"
import { NavigationBar } from "../NavigationBar/parent"
import rsr from "react-string-replace"

export const query = graphql`
  query($slug: String!) {
    strapiListOfCountries(slug: { eq: $slug }) {
      id
      Active
      Available
      slug
      country_en
      country_id
      continent_en
      continent_id
      estimate_en
      estimate_id
      price
      limit
      iso
      iso_3
    }
  }
`

function SendMoney({ data }) {
  const PathContent = PathJson()
  const MainPageContent = MainPageJson()
  const SendMoneyContent = SendMoneyJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isEurope = location.pathname.includes("send-money-to-europe")
  const EuropeData = {
    id: data.strapiListOfCountries.id,
    Active: data.strapiListOfCountries.Active,
    Available: data.strapiListOfCountries.Available,
    slug: data.strapiListOfCountries.slug,
    country_en: "Europe",
    country_id: "Eropa",
    continent_en: "Europe",
    continent_id: "Eropa",
    estimate_en: "D+1 banking day",
    estimate_id: "D+1 hari kerja",
    price: "74,000",
    limit: "14,000 EUR",
    iso: "EU",
    iso_3: "EEE",
  }
  const country = isEurope ? EuropeData : data.strapiListOfCountries
  const countryName = isEnglish ? country.country_en : country.country_id

  
  useEffect(() => {
    localStorage.setItem("iso_3", data.strapiListOfCountries.iso_3 || "")
  }, [])

  
  return (
    <div className="maxwidth">
      <Seo title="send-money" country={country} />
      <Header
        props={MainPageContent.Header}
        propsLink={PathContent}
        isEnglish={isEnglish}
        title={rsr(
          SendMoneyContent.Header.title,
          "{country}",
          () => countryName
        )}
        _title={{ style: { color: "#5A489C" } }}
      />
      <NavigationBar />
      <HowTo
        country={country}
        propsData={SendMoneyContent.How}
        isEnglish={isEnglish}
      />
      <Cost
        propsData={SendMoneyContent.Cost}
        country={country}
        isEnglish={isEnglish}
      />
      <DeliveryTime
        propsData={SendMoneyContent.Delivery}
        country={country}
        isEnglish={isEnglish}
      />
      <VideoMainSection
        props={MainPageContent.Video}
        country={country}
        isEnglish={isEnglish}
        propsData={SendMoneyContent.Video}
      />
      <RegisterButton isEnglish={isEnglish} />
      <FeatureSection props={MainPageContent.Advantages} />
      {/* <Testimonies
        propsData={SendMoneyContent.Testimonies}
        isEnglish={isEnglish}
      /> */}
      {/* <DownloadSection props={MainPageContent.Download} />
      <Media />
      <MissionSection
        props={MainPageContent.Mission}
        country={country}
        isEnglish={isEnglish}
        propsData={SendMoneyContent.Mission}
      /> */}
      <Download
        props={MainPageContent.Download}
        propsSendMoney={SendMoneyContent.Download}
        isDownloadTitle
      />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SendMoney
