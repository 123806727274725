import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ActionButton } from "../Affiliate/AffiliateStyle"

export const RegisterButton = ({ isEnglish }) => {
  const text = isEnglish ? "Register for Free Now!" : "Daftar Gratis Sekarang!"
  const link = isEnglish ? "/en/download/" : "/download/"
  return (
    <div className="text-center" style={{ marginBottom: 150 }}>
      <Link to={link}>
        <ActionButton>{text}</ActionButton>
      </Link>
    </div>
  )
}

const Button = styled.button``
