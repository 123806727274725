import React from "react"

function AccordionHowTo({ title, content, active, setActive, blnone }) {
  return (
    <>
      <div className={(active === title ? "show" : "") + " accordiondiv" + (blnone ? " blnone" : "")}>
        <div className={(active === title ? "bold" : "") + " accordionHeading"}>
          <div className="container">
            <h5 onClick={()=> setActive(title)}>{title}</h5>
          </div>
        </div>

        <div className={(active === title ? "show" : "") + " accordionContent"}>
          <div className="container">
            <p>
              {content}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccordionHowTo
