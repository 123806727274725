import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { MainTitle } from "./SendMoneyStyle"
import CaretYellow from "../../images/biaya-caret-yellow.svg"
import rocketImg from "../../images/send-money-rocket.png"
import styled from "styled-components"
import { BackgroundImg } from "../HomeComponents/HowToUse"
import bgGreyRotate from "../../images/logo-big-grey.svg"

function Cost({ propsData, country, isEnglish }) {
  const { Title, Desc1, Desc2, Desc6, Price1, Price2, Price3 } = propsData || {}
  const price = country?.price || ""
  const countryName = isEnglish ? country.country_en : country.country_id
  return (
    <MainContainer>
      <Row>
        <MainCol lg={9}>
          <MainDiv>
            <Row>
              <Col lg={8}>
                <MainTitle color="#5A489C" mbottom="100px">
                  {Title}
                  {countryName}?
                </MainTitle>
                <MainDesc>
                  {Desc1}
                  {countryName}
                  {Desc2}
                  <span
                    style={{
                      fontFamily: "Typefez-black",
                      color: "#59479A",
                    }}
                  >
                    {price} IDR
                    <br />
                    <br />
                    {Desc6}
                  </span>
                </MainDesc>
              </Col>
              <Col lg={4}>
                <BigImg src={rocketImg} alt="icon" />
              </Col>
            </Row>
          </MainDiv>
        </MainCol>
        <MainCol lg={3}>
          <SecondDiv>
            <RightWhiteDiv>
              <RightWhiteText>{Price1}</RightWhiteText>
            </RightWhiteDiv>
            <RightPurpleDiv>
              <RightPurpleText>{Price2}</RightPurpleText>
              <RightPurpleText color="#F4D53D" fsize="32px">
                {price} IDR
              </RightPurpleText>
            </RightPurpleDiv>
            <RightPurpleDiv
              bcolor="none"
              bshadow="none"
              padding="10px 0px 0px 40px"
            >
              <RightPurpleText color="#5A489C" fsize="16px">
                {Price3}
              </RightPurpleText>
            </RightPurpleDiv>
          </SecondDiv>
        </MainCol>
      </Row>
      <BackgroundImg src={bgGreyRotate} alt="logo" width="500px" top="250px" left="800px"/>
    </MainContainer>
  )
}

export default Cost

const MainContainer = styled(Container)`
  padding: 0px 28px;
  margin-top: 150px;
  margin-bottom: 250px;
  position: relative;
`

const MainCol = styled(Col)`
  padding: 0px;
  position: relative;
  @media (max-width: 992px) {
    text-align: center;
  }
`

const MainDiv = styled.div`
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  /* position: absolute; */
  z-index: 8;
  overflow: hidden;
`

const MainDesc = styled.h6`
  font-family: "Typefez-reg";
  font-size: 18px;
  color: #333334;
`

const BigImg = styled.img`
  position: absolute;
  bottom: -70px;
  right: -50px;
  min-width: 300px;
  @media (max-width: 992px) {
    display: none;
  }
`

const SecondDiv = styled.div`
  margin: 50px 0px 0px -20px;
  position: relative;
  z-index: -1;
  @media (max-width: 992px) {
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const RightWhiteDiv = styled.div`
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 20px 20px 20px 40px;
  margin-bottom: -10px;
  @media (max-width: 992px) {
    padding: 20px 20px 20px 20px;
  }
`

const RightWhiteText = styled.p`
  font-family: "Typefez-reg";
  font-size: 18px;
  color: #333334;
  margin-bottom: 0;
`

const RightPurpleDiv = styled.div`
  width: 250px;
  background-color: ${p => p.bcolor || "#5a489c"};
  box-shadow: ${p => p.bshadow || "0px 10px 30px rgba(0, 0, 0, 0.12)"};
  border-radius: 15px;
  padding: ${p => p.padding || "20px 20px 20px 40px"};
  @media (max-width: 992px) {
    padding: 20px 20px 20px 20px;
  } ;
`

const RightPurpleText = styled.h6`
  font-family: "Typefez-med";
  font-size: ${p => p.fsize || "18px"};
  color: ${p => p.color || "#fff"};
  margin-bottom: 0;
`
