import styled from "styled-components"
import { Col } from "react-bootstrap"
import {Link} from 'gatsby'

export const DownloadShape = styled.div`
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  max-width: max-content;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 40px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
`

export const DownloadTitle = styled.h2`
  font-size: 36px;
  color: #48a9b5;
  margin-bottom: 1.5rem;
  @media (max-width: 414px) {
    font-size: 21px;
    br {
      display: none;
    }
  }
`

export const DownloadAppsP = styled.p`
  font-family: "GothamBook";
  color: #48a9b5;
`

export const DownloadAppsUl = styled.ul`
  font-family: "GothamMedium";
`
export const ColPhoneImg = styled(Col)`
  margin-top: 30px;
  max-height: 100px;
  @media (max-width: 414px) {
    max-height: 100px;
    text-align: center;
    max-height: max-content;
  }
`

export const PhoneImg = styled.img`
  height: 400px;
  @media (max-width: 414px) {
    width: 200px;
    height: auto;
  }
`

export const LinkClick = styled(Link)`
  color: #196e96;
`