import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { MainTitle } from "./SendMoneyStyle"
import clockImg from "../../images/send-money-clock.png"
import styled from "styled-components"

function DeliveryTime({ propsData, country, isEnglish }) {
  const { Title, Title1, Desc3 } = propsData || {}
  const countryName = isEnglish ? country.country_en : country.country_id
  const estimate = isEnglish ? country.estimate_en : country.estimate_id
  const limit = country.limit || ""
  const flagOrigin = `https://flagcdn.com/w40/id.png`
  const flagDestination = `https://flagcdn.com/w40/${country.iso.toLowerCase()}.png`
  return (
    <MainContainer>
      <MainDiv>
        <Row>
          <Col lg={7}>
            <MainTitle>
              {Title}
              {countryName}?
            </MainTitle>
            <Row className="mb-2">
              <Col lg={5}></Col>
              <Col lg={7} className="mb-2">
                <SmallDivWhite>
                  <CountryFlag src={flagOrigin} alt="flag" />
                  <ArrowSymbol>→</ArrowSymbol>
                  <CountryFlag src={flagDestination} alt="flag" />
                </SmallDivWhite>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col lg={5} className="mb-2">
                <ArrivalText>{Title1}</ArrivalText>
              </Col>
              <Col lg={7}>
                <SmallDivWhite>
                  <ArrivalText color="#5A489C">{estimate}</ArrivalText>
                </SmallDivWhite>
              </Col>
            </Row>
            <ArrivalText color="#F4D53D">
              {Desc3} {countryName}
              <br />
              <span style={{ fontFamily: "Typefez-black" }}>{limit}</span>
            </ArrivalText>
          </Col>
          <Col lg={5} style={{ position: "relative" }}>
            <BigImg src={clockImg} alt="clock" />
          </Col>
        </Row>
      </MainDiv>
    </MainContainer>
  )
}

export default DeliveryTime

const MainContainer = styled(Container)`
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 150px;
  margin-bottom: 150px;
`

const MainDiv = styled.div`
  background: #5a489c;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 50px;
  overflow: hidden;
`

const SmallDivWhite = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
`

const ArrivalText = styled.h5`
  font-family: "Typefez-med";
  color: ${p => p.color || "#fff"};
  font-size: 18px;
  margin-bottom: 0px;
`

const CountryFlag = styled.img`
  border: 1px solid black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`

const ArrowSymbol = styled.h5`
  color: #db64a0;
  font-family: "Typefez-black";
  font-size: 20px;
  margin-bottom: 0px;
`

const BigImg = styled.img`
  position: absolute;
  right: -50px;
  bottom: 0;
  @media (max-width: 992px) {
    display: none;
  }
`
