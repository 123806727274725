import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Col } from "react-bootstrap"
import {
  HowToH3,
  HowToContainer,
  HowToDiv,
  HowToSquareDiv,
  HowToButton,
  MainDiv,
} from "./SendMoneyStyle"
import { DotAccordion } from "../HomeComponents/DotAccordion"
import { CustomButton } from "../Buttons/parent"
import AccordionHowTo from "./AccordionHowTo"
import "./SendMoneyStyle.css"
import Img1ID from "../../images/1_Daftar_Akun_Gratis.png"
import Img2ID from "../../images/2_tentukan_jumlah_uang_yang_dikirim.png"
import Img3ID from "../../images/3_Verifikasi_identitas.png"
import Img4ID from "../../images/4_Masukkan_informasi_penerima_uang.png"
import Img5ID from "../../images/5_Lakukan_Pembayaran.png"
import Img6ID from "../../images/6_saatnya_bersantai.png"
import Img1EN from "../../images/1_Daftar_Akun_Gratis_EN.png"
import Img2EN from "../../images/2_tentukan_jumlah_uang_yang_dikirim_EN.png"
import Img3EN from "../../images/3_Verifikasi_identitas_EN.png"
import Img4EN from "../../images/4_Masukkan_informasi_penerima_uang_EN.png"
import Img5EN from "../../images/5_Lakukan_Pembayaran_EN.png"
import Img6EN from "../../images/6_saatnya_bersantai_EN.png"
import currencyRightImg from "../../images/currency-coin-right.png"
import currencyLeftImg from "../../images/currency-coin-left.png"
import styled from "styled-components"

function HowTo({ country, propsData, isEnglish }) {
  const Img1 = isEnglish ? Img1EN : Img1ID
  const Img2 = isEnglish ? Img2EN : Img2ID
  const Img3 = isEnglish ? Img3EN : Img3ID
  const Img4 = isEnglish ? Img4EN : Img4ID
  const Img5 = isEnglish ? Img5EN : Img5ID
  const Img6 = isEnglish ? Img6EN : Img6ID
  const [idActive, setIdActive] = useState("")
  const [defImage, setDefImage] = useState(Img1)
  const {
    Title,
    Title1,
    Content1,
    Title2,
    Content2,
    Title3,
    Content3,
    Title4,
    Content4,
    Title5,
    Content5,
    Title6,
    Content6,
    Button,
  } = propsData

  const DataList = [
    {
      eventId: "0",
      name: Title1,
      content: Content1,
    },
    {
      eventId: "1",
      name: Title2,
      content: Content2,
    },
    {
      eventId: "2",
      name: Title3,
      content: Content3,
    },
    {
      eventId: "3",
      name: Title4,
      content: Content4,
    },
    {
      eventId: "4",
      name: Title5,
      content: Content5,
    },
    {
      eventId: "5",
      name: Title6,
      content: Content6,
    },
  ]

  const handleActive = e => {
    setIdActive(e)
  }

  useEffect(() => {
    if (idActive === "0") {
      setDefImage(Img1)
    } else if (idActive === "1") {
      setDefImage(Img2)
    } else if (idActive === "2") {
      setDefImage(Img3)
    } else if (idActive === "3") {
      setDefImage(Img4)
    } else if (idActive === "4") {
      setDefImage(Img5)
    } else if (idActive === "5") {
      setDefImage(Img6)
    }
  }, [idActive])

  return (
    <HowToContainer>
      <HowToH3>
        {Title}
        {isEnglish ? country.country_en : country.country_id}
      </HowToH3>
      <HowToDiv>
        <Col md={8} style={{ padding: "0px 0px" }}>
          <MainDiv pad="40px 10px 30px 40px" margin="0px">
            <DotAccordion
              data={DataList}
              isCountries
              sendToParent={handleActive}
              mbottom="30px"
            />
            <Link to={isEnglish ? "/en/download" : "/download"}>
              <CustomButton padding="8px 20px">{Button}</CustomButton>
            </Link>
          </MainDiv>
        </Col>
        <Col md={4} className="text-center">
          <ImgPhone src={defImage} alt="how-to" />
        </Col>
      </HowToDiv>
      <CurrencyImg
        alt="currency"
        src={currencyLeftImg}
        top="150px"
        left="-200px"
      />
      <CurrencyImg
        alt="currency"
        src={currencyRightImg}
        top="150px"
        right="-200px"
      />
    </HowToContainer>
  )
}

export default HowTo

const CurrencyImg = styled.img`
  position: absolute;
  left: ${p => p.left};
  right: ${p => p.right};
  top: ${p => p.top};
  @media (max-width: 768px) {
    display: none;
  }
`

const ImgPhone = styled.img`
  max-height: 400px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`
